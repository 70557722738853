/**
 * A component to display a password criteria as a chip.
 */

import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { i18n } from '@geomagic/i18n';

const PasswordCriterionChip = (props) => {
  const { localizationKey, isValid, settingsValue } = props;

  /* COMPONENTS */

  if (!settingsValue) {
    return null;
  }

  return (
    <Chip
      label={i18n.t(localizationKey)}
      sx={[
        ({ spacing }) => ({
          margin: spacing(0.5),
        }),
      ]}
      icon={
        isValid ? (
          <CheckCircleIcon
            sx={[
              ({ palette }) => ({
                color: palette.success.main,
              }),
            ]}
          />
        ) : (
          <HighlightOffIcon />
        )
      }
      variant={'outlined'}
    />
  );
};

PasswordCriterionChip.propTypes = {
  isValid: PropTypes.bool,
  localizationKey: PropTypes.string.isRequired,
  settingsValue: PropTypes.number,
};

export default PasswordCriterionChip;
