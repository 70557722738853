import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRxDB } from 'rxdb-hooks';
import AddIcon from '@mui/icons-material/Add';

import { FAB_SX_PROPS } from '@consts';
import getEntityTemplate from '@database/getEntityTemplate';
import getDocTemplate from '@database/getDocTemplate';
import { Trigger } from '@geomagic/core';
import StackedDialog from '@geomagic/nam-react-core/components/StackedDialog';
import DispatchWizard from './DispatchWizard';

const AddDraftTrigger = (props) => {
  const {
    entityClass,
    entityClasses,
    getDocRelations,
    getPreviousMap,
    isMobile,
    isOnline,
    mapProps,
    onAddedDraft,
    triggerSX = {},
    triggerText,
    user,
    userId,
    variant = 'fab',
  } = props;

  const database = useRxDB();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [dialogTitle, setDialogTitle] = useState();

  /**
   *  EVENT HANDLER
   */

  const handleAddDraft = async (event) => {
    event.stopPropagation();
    const previousMap = getPreviousMap && getPreviousMap();

    if (entityClasses && entityClass) {
      const collection = await database['dispatches'];
      const entity = getEntityTemplate(entityClass);
      const doc = getDocTemplate({
        entity,
        isDraft: true,
        mapProps,
        relations: getDocRelations ? getDocRelations() : [],
        userId,
      });

      const dispatch = await collection.insert(doc);

      if (dispatch) {
        onAddedDraft && (await onAddedDraft(entity));

        setDialogProps({ entityId: entity?.id, previousMap });
        setDialogOpen(true);
      }
    }
  };

  const handleCloseDialog = () => setDialogOpen(false);

  /**
   *  COMPONENTS
   */

  const DialogContent = (
    <DispatchWizard
      entityClass={entityClass}
      entityClasses={entityClasses}
      handleClose={handleCloseDialog}
      isMobile={isMobile}
      isOnline={isOnline}
      mapProps={mapProps}
      setDialogTitle={setDialogTitle}
      user={user}
      {...dialogProps}
    />
  );

  return (
    <>
      <Trigger
        color="primary"
        icon={<AddIcon />}
        onClick={handleAddDraft}
        variant={variant}
        {...(variant === 'fab' && {
          size: 'medium',
          sx: {
            ...FAB_SX_PROPS,
            ...triggerSX,
          },
        })}
      >
        {triggerText}
      </Trigger>
      <StackedDialog
        content={DialogContent}
        isFullscreen={true}
        handleClose={handleCloseDialog}
        open={isDialogOpen}
        title={dialogTitle}
      />
    </>
  );
};

AddDraftTrigger.propTypes = {
  className: PropTypes.string,
  entityClass: PropTypes.object,
  entityClasses: PropTypes.array,
  getDocRelations: PropTypes.func,
  getPreviousMap: PropTypes.func,
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  mapProps: PropTypes.object,
  onAddedDraft: PropTypes.func,
  triggerSX: PropTypes.object,
  triggerText: PropTypes.string,
  user: PropTypes.object,
  userId: PropTypes.number,
  variant: PropTypes.string,
};

export default AddDraftTrigger;
