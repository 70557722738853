import gql from 'graphql-tag';

const MutationAbortLogin = gql`
  mutation MutationAbortLogin {
    abortLogin {
      status
    }
  }
`;

export default MutationAbortLogin;
