import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import DefaultAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { ContentRoot } from '@geomagic/layout';
import DocumentListImageViewer from '@components/DocumentListImageViewer';
import DocumentUpload from '@components/DocumentUpload';
import { DEFAULT_DOCUMENT_ACCEPT_FORMATS } from '@consts';

const useStyles = makeStyles()(({ palette, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    borderBottom: `1px solid ${palette.divider}`,
    display: 'flex',
    padding: spacing(2),
  },
  endAction: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
  },
  bottomToolbar: {
    background: palette.background.default,
    borderTop: `1px solid ${palette.divider}`,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: spacing(),
    paddingTop: spacing(),
  },
}));

const DispatchDocuments = (props) => {
  const {
    CloseComponent,
    doc,
    entityClasses,
    isMobile,
    isOfflineCachePermanent,
    isOnline,
    isReadOnly,
    onChange,
    triggerProps,
  } = props;
  const entity = doc.getPatchedEntity();
  const { documents = [] } = entity;

  const { classes } = useStyles();
  const client = useApolloClient();

  /**
   *  EVENT HANDLER
   */

  const handleChange = async (newDocuments) => {
    const newPatch = {
      op: 'replace',
      path: '/documents',
      value: newDocuments,
    };

    await onChange(newPatch);
  };

  return (
    <Fragment>
      <ContentRoot className={classes.root} scrollable={false} withPadding={false}>
        <DocumentListImageViewer
          client={client}
          doc={doc}
          documents={documents}
          isOfflineCachePermanent={isOfflineCachePermanent}
          isOnline={isOnline}
          placeholderTitle={i18n.t('dispatch.placeholder.uploadDocument.title')}
          placeholderSubtitle={i18n.t('dispatch.placeholder.uploadDocument.subtitle')}
          {...(!isReadOnly && { onDelete: handleChange })}
        />
      </ContentRoot>
      {!isReadOnly && (
        <DefaultAppBar position="static" color="inherit">
          <Toolbar className={classes.bottomToolbar}>
            <DocumentUpload
              accept={DEFAULT_DOCUMENT_ACCEPT_FORMATS}
              className={classes.trigger}
              doc={doc}
              documents={documents}
              entityClasses={entityClasses}
              isMobile={isMobile}
              label={i18n.t('button.add')}
              onChange={handleChange}
              triggerProps={triggerProps}
              withAttributesForm
            />
            {CloseComponent}
          </Toolbar>
        </DefaultAppBar>
      )}
    </Fragment>
  );
};

DispatchDocuments.propTypes = {
  CloseComponent: PropTypes.node,
  doc: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  isOfflineCachePermanent: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  triggerProps: PropTypes.object,
};

export default DispatchDocuments;
