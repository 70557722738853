import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';

import { i18n } from '@geomagic/i18n';
import { Trigger, notifyUser } from '@geomagic/core';

import { AUTO_HIDE_DURATION, PRIMARY_TRIGGER_PROPS, SECONDARY_TRIGGER_PROPS } from '../../consts';

import QueryPasswordSettings from '../../graphql/queries/QueryPasswordSettings';
import MutationChangeExpiredPassword from '../../graphql/mutations/MutationChangeExpiredPassword';
import MutationAbortLogin from '../../graphql/mutations/MutationAbortLogin';

import PasswordForm from '../../nam-react-core/components/Password/PasswordForm';
import CardActions from '../../nam-react-core/components/CardActions';
import useSubmitKey from '../../utils/useSubmitKey';

const NOT_LOGGED_IN_STATUS = 'NOT_LOGGED_IN';

const RefreshPassword = (props) => {
  const { updateGlobalState } = props;

  const [validPassword, setValidPassword] = useState(null);

  const canSave = !!validPassword;

  /* QUERIES */

  const { data: passwordCriteria, loading: loadingPasswordSetting } = useQuery(QueryPasswordSettings);

  /* MUTATIONS */

  const [refreshPassword] = useMutation(MutationChangeExpiredPassword);
  const [abortLogin] = useMutation(MutationAbortLogin);

  /* EVENT HANDLER */

  const handleRefreshPassword = async () => {
    const { data } = await refreshPassword({
      variables: {
        oldPassword: validPassword.oldPassword,
        newPassword: validPassword.newPassword,
      },
    });

    const status = data?.status || NOT_LOGGED_IN_STATUS;

    if (status === NOT_LOGGED_IN_STATUS) {
      notifyUser({
        type: 'success',
        autoHideDuration: AUTO_HIDE_DURATION,
        message: i18n.t('notification.refreshPassword'),
      });
    }

    if (status !== NOT_LOGGED_IN_STATUS) {
      notifyUser({
        type: 'error',
        autoHideDuration: AUTO_HIDE_DURATION,
        message: i18n.t('notification.errorRefreshPassword'),
      });
    }

    updateGlobalState({ loginStatus: status });
  };

  const handleCancel = async () => {
    const { data } = await abortLogin();
    const { status } = data.abortLogin;

    updateGlobalState({ loginStatus: status });
  };

  /* HOOKS */

  useSubmitKey(handleRefreshPassword, canSave);

  /* COMPONENTS */

  return (
    <>
      <Typography
        variant="body2"
        sx={[
          ({ spacing }) => ({
            marginTop: spacing(2),
          }),
        ]}
      >
        {i18n.t('refreshPassword.passwordExpiredInfo')}
      </Typography>
      <PasswordForm
        loadingPasswordSetting={loadingPasswordSetting}
        onHandleCancel={handleCancel}
        passwordCriteria={passwordCriteria}
        setValidPassword={setValidPassword}
      />
      <CardActions>
        <Trigger {...SECONDARY_TRIGGER_PROPS} onClick={handleCancel}>
          {i18n.t('button.cancel')}
        </Trigger>
        <Trigger
          {...PRIMARY_TRIGGER_PROPS}
          sx={[
            ({ spacing }) => ({
              marginLeft: spacing(),
            }),
          ]}
          disabled={!canSave}
          onClick={handleRefreshPassword}
        >
          {i18n.t('button.refresh')}
        </Trigger>
      </CardActions>
    </>
  );
};

RefreshPassword.propTypes = {
  updateGlobalState: PropTypes.func.isRequired,
};

export default RefreshPassword;
