import React from 'react';
import PropTypes from 'prop-types';
import HelpIcon from '@mui/icons-material/Help';
import { i18n } from '@geomagic/i18n';
import { FORM_COMPONENT_CHILDREN_KEY, FORM_ELEMENT_BLOCK } from './consts';
import FormPage from './FormElementPage';
import FormElementBlock from './FormElementBlock';
import FormElementRow from './FormElementRow';
import FormElement from './FormElement';
import isMarkdownDescriptionType from './utils/isMarkdownDescriptionType';
import MarkDownTrigger from './MarkDownTrigger';
import NewFunclocTrigger from './NewFunclocTrigger';

const FORM = 'Form';
const FORM_ELEMENT_ROW = 'FormElementRow';

const getElementByTypename = ({ typename }) => {
  switch (typename) {
    case FORM:
      return FormPage;
    case FORM_ELEMENT_BLOCK:
      return FormElementBlock;
    case FORM_ELEMENT_ROW:
      return FormElementRow;
    default:
      return FormElement;
  }
};

const FormComponent = (props) => {
  const {
    activePanel,
    context,
    data,
    doc,
    entityClasses,
    getPreviousMap,
    id,
    isDefectStatusSelected,
    mapProps,
    newChildIndex,
    onEditNewFuncloc,
    onRemoveFuncloc,
    path,
    parentPath,
    parentId,
    activeStep,
    setActivePanel,
    user,
    ...elementProps
  } = props;

  const { isMobile } = context;
  const { children = [], description, displayedIfDefect, name, usedAsTemplate = false, typename } = data;

  const isFormOrBlock = typename === FORM || typename === FORM_ELEMENT_BLOCK;
  const isExpandedChild = isFormOrBlock || (!isFormOrBlock && parentId === activePanel);
  const isElementDisplayed = !displayedIfDefect || isDefectStatusSelected;
  const Component = getElementByTypename(data);
  const isMarkdown = isMarkdownDescriptionType(data);

  /* COMPONENTS */

  const InfoTrigger = isMarkdown && (
    <MarkDownTrigger
      icon={<HelpIcon />}
      isMobile={isMobile}
      source={description}
      title={i18n.t('assignment.dialog.description.title', { variables: { name } })}
      variant="icon"
    />
  );

  const Children = isExpandedChild
    ? children?.map((child, idx) => (
        <FormComponent
          activePanel={activePanel}
          key={child.id}
          activeStep={activeStep}
          context={context}
          data={child}
          doc={doc}
          entityClasses={entityClasses}
          getPreviousMap={getPreviousMap}
          id={child.id}
          mapProps={mapProps}
          newChildIndex={children.length}
          onEditNewFuncloc={onEditNewFuncloc}
          onRemoveFuncloc={onRemoveFuncloc}
          parentPath={`${path}/${FORM_COMPONENT_CHILDREN_KEY}`}
          parentId={parentId ? parentId : child.id}
          path={`${path}/${FORM_COMPONENT_CHILDREN_KEY}/${idx}`}
          setActivePanel={setActivePanel}
          user={user}
        />
      ))
    : [];

  return isElementDisplayed ? (
    <>
      {usedAsTemplate ? (
        <NewFunclocTrigger
          data={data}
          entityClasses={entityClasses}
          newChildIndex={newChildIndex}
          onEditNewFuncloc={onEditNewFuncloc}
          parentPath={parentPath}
        />
      ) : (
        <Component
          activePanel={activePanel}
          data={data}
          doc={doc}
          context={context}
          entityClasses={entityClasses}
          getPreviousMap={getPreviousMap}
          infoTrigger={InfoTrigger}
          isDefectStatusSelected={isDefectStatusSelected}
          mapProps={mapProps}
          onEditNewFuncloc={onEditNewFuncloc}
          onRemoveFuncloc={onRemoveFuncloc}
          path={path}
          parentId={parentId}
          setActivePanel={setActivePanel}
          user={user}
          {...elementProps}
        >
          {Children}
        </Component>
      )}
    </>
  ) : null;
};

FormComponent.propTypes = {
  activeStep: PropTypes.number,
  context: PropTypes.object,
  data: PropTypes.object,
  doc: PropTypes.object,
  entityClass: PropTypes.object,
  entityClasses: PropTypes.array,
  getPreviousMap: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDefectStatusSelected: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
  newChildIndex: PropTypes.number,
  onEditNewFuncloc: PropTypes.func,
  onRemoveFuncloc: PropTypes.func,
  parentPath: PropTypes.string,
  path: PropTypes.string,
  parentId: PropTypes.number,
  user: PropTypes.object.isRequired,
  activePanel: PropTypes.number,
  setActivePanel: PropTypes.func.isRequired,
};

export default FormComponent;
