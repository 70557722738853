import gql from 'graphql-tag';

const MutationChangeExpiredPassword = gql`
  mutation ($oldPassword: String!, $newPassword: String!) {
    changeExpiredPassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      status
      user {
        id
        loginName
        fullName
      }
    }
  }
`;

export default MutationChangeExpiredPassword;
