import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';

import { OFFLINE_DOCUMENT_SYNC_KEY, PRIMARY_TRIGGER_PROPS, SECONDARY_TRIGGER_PROPS } from '@consts';
import getFilePropsFromDocumentEntity from '@database/getFilePropsFromDocumentEntity';
import StackedDialog from '@geomagic/nam-react-core/components/StackedDialog';
import useStickyState from '@geomagic/nam-react-core/utils/useStickyState';

const OfflineDocuments = (props) => {
  const { dispatches, handleSetCacheSize, isOnline } = props;

  const [isSyncDocuments, setIsSyncDocuments] = useStickyState(OFFLINE_DOCUMENT_SYNC_KEY, true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  /* EVENT HANDLER */

  const handleOnSwitch = async (newChecked) => {
    if (newChecked) {
      setIsSyncDocuments(newChecked);
      enqueueSnackbar(i18n.t('offlineDocuments.notification.synchronized'), {
        key: OFFLINE_DOCUMENT_SYNC_KEY,
        preventDuplicate: true,
        variant: 'success',
      });
    } else {
      setOpenDeleteDialog(true);
    }
  };

  const handleDeleteDocuments = async () => {
    const dispatchDocs = await dispatches.find().exec();

    if (!isEmpty(dispatchDocs) && isDeleting) {
      for (let i = 0; i < dispatchDocs.length; i++) {
        const databaseDoc = dispatchDocs[i];
        const documents = databaseDoc?.entity?.documents;
        for (let j = 0; j < documents.length; j++) {
          const doc = documents[j];
          const { hash, id } = getFilePropsFromDocumentEntity(doc);

          const attachment = databaseDoc.getAttachment(hash);
          const isRemoteDocument = !!id;

          if (attachment && isRemoteDocument) {
            await attachment.remove();
          }
        }
      }
      await handleSetCacheSize();
    }

    setIsSyncDocuments(false);
    setOpenDeleteDialog(false);

    enqueueSnackbar(i18n.t('offlineDocuments.notification.unsynchronized'), {
      key: OFFLINE_DOCUMENT_SYNC_KEY,
      preventDuplicate: true,
      variant: 'success',
    });
  };

  /* COMPONENTS */

  const DialogContent = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        gap: (theme) => theme.spacing(1),
        padding: (theme) => theme.spacing(0, 1),
      }}
    >
      <Typography
        variant="body1"
        sx={{
          whiteSpace: 'break-spaces',
        }}
      >
        {i18n.t('dialog.offlineDocuments.content')}
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            edge="start"
            checked={isDeleting}
            color="primary"
            onChange={(event) => setIsDeleting(event.target.checked)}
          />
        }
        label={i18n.t('dialog.offlineDocuments.checkbox')}
      />
    </Box>
  );

  const ActionsComponent = (
    <>
      <Trigger {...SECONDARY_TRIGGER_PROPS} onClick={() => setOpenDeleteDialog(false)}>
        {i18n.t('button.close')}
      </Trigger>

      <Trigger {...PRIMARY_TRIGGER_PROPS} onClick={async () => await handleDeleteDocuments()}>
        {i18n.t('button.turnOff')}
      </Trigger>
    </>
  );

  return (
    <List sx={{ padding: 0, width: '100%' }}>
      <ListItem disableGutters>
        <ListItemText primary={i18n.t('label.preference.offlineDocuments.button')} />
        <ListItemSecondaryAction>
          <Switch
            checked={isSyncDocuments}
            color="primary"
            disabled={!isOnline && !isSyncDocuments}
            onChange={async (_event, newChecked) => await handleOnSwitch(newChecked)}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <StackedDialog
        actions={ActionsComponent}
        content={DialogContent}
        isFullscreen={false}
        handleClose={() => setOpenDeleteDialog(false)}
        open={openDeleteDialog}
        title={i18n.t('dialog.offlineDocuments.title')}
      />
    </List>
  );
};

OfflineDocuments.propTypes = {
  dispatches: PropTypes.object,
  handleSetCacheSize: PropTypes.func.isRequired,
  isOnline: PropTypes.bool,
};

export default OfflineDocuments;
