import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import { Trigger, notifyUser } from '@geomagic/core';
import { hideModal } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import useOnlineStatus from '@utils/useOnlineStatus';

import { AUTO_HIDE_DURATION, PRIMARY_TRIGGER_PROPS } from '../../../consts';

import QueryPasswordSettings from '../../../graphql/queries/QueryPasswordSettings';
import MutationChangePassword from '../../../graphql/mutations/MutationChangePassword';
import PasswordForm from '../../../nam-react-core/components/Password/PasswordForm';
import CardActions from '../../../nam-react-core/components/CardActions';
import useSubmitKey from '../../../utils/useSubmitKey';

const PasswordChange = (props) => {
  const { client } = props;
  const isOnline = useOnlineStatus();

  const [validPassword, setValidPassword] = useState(null);
  const [passwordCriteria, setPasswordCriteria] = useState(null);
  const [loadingPasswordSetting, setLoadingPasswordSetting] = useState(true);

  const canSave = !!validPassword && isOnline;

  /* EVENT HANDLER */

  const handleClose = () => hideModal();

  const handleSetPasswortSettings = () => {
    client
      .query({ query: QueryPasswordSettings })
      .then(({ data }) => {
        setPasswordCriteria(data);
      })
      .finally(() => {
        setLoadingPasswordSetting(false);
      });
  };

  const handleChangePassword = async () =>
    await client.mutate({
      mutation: MutationChangePassword,
      variables: {
        oldPassword: validPassword.oldPassword,
        newPassword: validPassword.newPassword,
      },
    });

  const handleSubmitForm = () => {
    handleChangePassword()
      .then(() => {
        handleClose();
        notifyUser({
          type: 'success',
          autoHideDuration: AUTO_HIDE_DURATION,
          message: i18n.t('notification.passwordChanged'),
        });
      })
      .catch(() => {
        notifyUser({
          type: 'error',
          autoHideDuration: AUTO_HIDE_DURATION,
          message: i18n.t('notification.errorRefreshPassword'),
        });
      });
  };

  /* HOOKS */

  useSubmitKey(handleSubmitForm, canSave);

  /* EFFECTS */

  // Retrieve Password Settings solely during the mounting process.
  useEffect(() => {
    handleSetPasswortSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* COMPONENTS */

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: ({ spacing }) => spacing(2),
        gap: ({ spacing }) => spacing(1),
      }}
    >
      <PasswordForm
        loadingPasswordSetting={loadingPasswordSetting}
        onHandleCancel={handleClose}
        passwordCriteria={passwordCriteria}
        setValidPassword={setValidPassword}
      />
      <CardActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <Trigger
          {...PRIMARY_TRIGGER_PROPS}
          sx={{
            width: 'fit-content',
          }}
          disabled={!canSave}
          onClick={handleSubmitForm}
        >
          {i18n.t('button.savePassword')}
        </Trigger>
      </CardActions>
    </Box>
  );
};

PasswordChange.propTypes = {
  client: PropTypes.object.isRequired,
};

export default PasswordChange;
