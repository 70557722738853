import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import Box from '@mui/material/Box';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { AutoForm } from '@geomagic/forms';

import MutationLogin from '@graphql/mutations/MutationLogin';
import { PRIMARY_TRIGGER_PROPS } from '@consts';

import getSchemaAndUI from './getSchemaAndUI';

const AUTO_FORM_ID = 'LOGIN_FORM';

const LoginForm = (props) => {
  const { onLogin } = props;

  const [schemaAndUI] = useState(() => getSchemaAndUI());

  /* MUTATIONS */

  const [login, { loading }] = useMutation(MutationLogin);

  /* EVENT HANDLER */

  const handleLogin = (variables) =>
    login({ variables }).then(({ data }) => {
      onLogin(data?.login);
    });

  return (
    <AutoForm id={AUTO_FORM_ID} onSubmit={handleLogin} isSubmitOnEnter={false} {...schemaAndUI}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column',
          marginTop: 1,
        }}
      >
        <Trigger {...PRIMARY_TRIGGER_PROPS} isLoading={loading} disabled={loading} type="submit">
          {i18n.t('button.login')}
        </Trigger>
      </Box>
    </AutoForm>
  );
};

LoginForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
  ssoLink: PropTypes.element,
};

export default LoginForm;
