import React, { lazy, Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';

import getComponentName from './utils/getComponentName';

const FormElementDocuments = lazy(() => import('./elements/FormElementDocuments'));
const FormElementEnum = lazy(() => import('./elements/FormElementEnum'));
const FormElementEnumSelect = lazy(() => import('./elements/FormElementEnumSelect'));
const FormElementField = lazy(() => import('./elements/FormElementField'));
const FormElementFieldInput = lazy(() => import('./elements/FormElementFieldInput'));
const FormElementFieldData = lazy(() => import('./elements/FormElementFieldData'));
const FormElementFieldText = lazy(() => import('./elements/FormElementFieldText'));
const FormElementLocRecording = lazy(() => import('./elements/FormElementLocRecording'));
const FormElementPictures = lazy(() => import('./elements/FormElementPictures'));

const getComponent = (componentName) => {
  switch (componentName) {
    case 'FormElementDocuments':
      return FormElementDocuments;
    case 'FormElementEnum':
      return FormElementEnum;
    case 'FormElementEnumSelect':
      return FormElementEnumSelect;
    case 'FormElementField':
      return FormElementField;
    case 'FormElementFieldInput':
      return FormElementFieldInput;
    case 'FormElementFieldData':
      return FormElementFieldData;
    case 'FormElementFieldText':
      return FormElementFieldText;
    case 'FormElementLocRecording':
      return FormElementLocRecording;
    case 'FormElementPictures':
      return FormElementPictures;
    default:
      return null;
  }
};

const FormElement = (props) => {
  const { context, data, ...elementProps } = props;
  const { typename, options } = data;

  const Component = useMemo(() => {
    const componentName = getComponentName(options);
    return getComponent(componentName ? typename + componentName : typename);
  }, [options, typename]);

  return (
    <Suspense fallback={null}>
      <Component context={context} data={data} {...elementProps} />
    </Suspense>
  );
};

FormElement.propTypes = {
  children: PropTypes.node,
  context: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default FormElement;
