import { useEffect } from 'react';

const ENTER_KEY = 'Enter';
const KEYDOWN_EVENT = 'keydown';

const useSubmitKey = (action, canSave) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === ENTER_KEY && canSave) {
        action();
      }
    };

    window.addEventListener(KEYDOWN_EVENT, handleKeyDown);

    return () => {
      window.removeEventListener(KEYDOWN_EVENT, handleKeyDown);
    };
  }, [action, canSave]);
};

export default useSubmitKey;
