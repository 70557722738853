import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { AutoForm, submitForm } from '@geomagic/forms';

import {
  DEFAULT_TEXT_FIELD_PROPS,
  PRIMARY_TRIGGER_PROPS,
  LOGIN_WITH_CREDENTIALS_PATH,
  SECONDARY_TRIGGER_PROPS,
} from '../../consts';

import MutationAbortTwoFactor from '../../graphql/mutations/MutationAbortTwoFactor';
import MutationVerifySecondFactor from '../../graphql/mutations/MutationVerifySecondFactor';

const TWO_FACTOR_AUTHENTIFICATION = 'TwoFactorAuthenticationID';
const ICON_SIZE = 120;

const getAutoFormProps = () => ({
  schema: {
    type: 'object',
    required: ['code'],
    properties: {
      code: {
        type: 'number',
        title: i18n.t('twoFactor.label.code'),
      },
    },
  },
  ui: {
    code: {
      ...DEFAULT_TEXT_FIELD_PROPS,
    },
  },
});

const TwoFactor = (props) => {
  const { onLogin, updateGlobalState } = props;
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [autoFormProps] = useState(() => getAutoFormProps());

  /* MUTATIONS */

  const [verifySecondFactor] = useMutation(MutationVerifySecondFactor);
  const [abortLogin] = useMutation(MutationAbortTwoFactor);

  /* EVENT HANDLER */

  const handleSubmitForm = () => {
    submitForm(TWO_FACTOR_AUTHENTIFICATION);
  };

  const onVerifySecondFactor = async (values) => {
    try {
      await verifySecondFactor({
        variables: {
          code: values.code,
        },
      }).then(({ data }) => {
        onLogin(data?.verifySecondFactor);
        updateGlobalState({ twoFactorPending: false });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleAbort = () => {
    abortLogin().then(() => {
      updateGlobalState({ twoFactorPending: false });
      history.push(LOGIN_WITH_CREDENTIALS_PATH);
      enqueueSnackbar(i18n.t('twoFactor.notification.abort'), {
        persist: false,
        preventDuplicate: true,
        variant: 'info',
      });
    });
  };

  return (
    <Box
      sx={[
        ({ spacing }) => ({
          width: '100%',
          height: '90%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: spacing(2),

          '& input::-webkit-inner-spin-button, input::-webkit-outer-spin-button ': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        }),
      ]}
    >
      <SmartphoneIcon
        fontSize="large"
        sx={[
          ({ palette, typography }) => ({
            color: palette.grey[300],
            fontSize: typography.pxToRem(ICON_SIZE),
          }),
        ]}
      />
      <Typography variant="h6" align="center">
        {i18n.t('twoFactor.title')}
      </Typography>
      <Typography variant="body1" align="center">
        {i18n.t('twoFactor.description')}
      </Typography>
      <AutoForm id={TWO_FACTOR_AUTHENTIFICATION} onSubmit={onVerifySecondFactor} {...autoFormProps} />
      <Box
        sx={[
          ({ spacing }) => ({
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'row',
            gap: spacing(2),
            width: '100%',
            marginTop: spacing(2),
          }),
        ]}
      >
        <Trigger onClick={handleAbort} {...SECONDARY_TRIGGER_PROPS}>
          {i18n.t('button.cancel')}
        </Trigger>
        <Trigger type="submit" onClick={handleSubmitForm} {...PRIMARY_TRIGGER_PROPS}>
          {i18n.t('button.verifyTwoFactor')}
        </Trigger>
      </Box>
    </Box>
  );
};

TwoFactor.propTypes = {
  onLogin: PropTypes.func.isRequired,
  updateGlobalState: PropTypes.func.isRequired,
};

export default TwoFactor;
