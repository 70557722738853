import getDefaultFeatureStyle from '@components/Map/utils/getFeatureStyle';

const getFeatureStyle = (primaryColor, theme) => (feature) => {
  const backgroundColor = primaryColor || theme?.palette?.primary[200];

  const style = getDefaultFeatureStyle({ feature, backgroundColor });

  return style;
};

export default getFeatureStyle;
