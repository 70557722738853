import getSourceOptions from './getSourceOptions';
import { MAX_TILE_ZOOM_LEVEL } from '../../AppBar/OfflineMap/consts';
import { DEFAULT_VECTOR_STYLE } from '../consts';

/**
 * Get options for a vector tile layer. Preload loads the lower zoom levels for the current extent.
 * Max Zoom calls for tiles in lower zoom levels to allow overzooming.
 * @param {object} options
 * @param {object} sourceOptions
 * @returns {object}
 */
const getVectorTileOptions = (options, sourceOptions) => {
  return {
    layerValues: JSON.stringify({
      ...options,
      renderMode: 'vector',
      preload: MAX_TILE_ZOOM_LEVEL,
      style: DEFAULT_VECTOR_STYLE,
    }),
    sourceType: 'MVT',
    values: JSON.stringify(getSourceOptions({ ...sourceOptions, maxZoom: MAX_TILE_ZOOM_LEVEL })),
  };
};

export default getVectorTileOptions;
