import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const AssignmentProviderContext = React.createContext({});

const AssignmentProvider = (props) => {
  const { assignmentDoc, children } = props;
  const [assignment, setAssignment] = useState(assignmentDoc.getPatchedEntity());

  const updateAssignment = useCallback(() => {
    setAssignment(assignmentDoc.getPatchedEntity());
  }, [assignmentDoc]);

  return (
    <AssignmentProviderContext.Provider
      value={{
        assignment,
        updateAssignment,
      }}
    >
      {children}
    </AssignmentProviderContext.Provider>
  );
};

export const useAssignment = () => useContext(AssignmentProviderContext);

AssignmentProvider.propTypes = {
  assignmentDoc: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default AssignmentProvider;
