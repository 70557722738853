import { useCallback } from 'react';

import getFileByDownloadUrl from '../database/getFileByDownloadUrl';
import getDownloadUrl from '../database/getDownloadUrl';
import { getReference } from '@geomagic/geonam';

import { i18n } from '@geomagic/i18n';

import useLoadingSnackbar from '@utils/useLoadingSnackbar';
import QueryDocument from '../graphql/queries/QueryDocument';
import getFilePropsFromDocumentEntity from '../database/getFilePropsFromDocumentEntity';

const useOfflineDocuments = (doc, client) => {
  const enqueueLoadingSnackbar = useLoadingSnackbar();

  const fetchDocument = useCallback(
    async (item) => {
      const { data } = await client.query({
        query: QueryDocument,
        fetchPolicy: 'network-only',
        variables: {
          entityReference: getReference(item),
        },
      });
      return data?.entity || {};
    },
    [client]
  );

  const addOfflineDocument = useCallback(
    async (item) => {
      const saveDocument = async () => {
        const { id, name, hash, type } = item;
        const fileHash = hash || id;

        const document = await fetchDocument(item);
        const { downloadPath } = getFilePropsFromDocumentEntity(document);

        const url = getDownloadUrl(downloadPath);
        const file = await getFileByDownloadUrl({ url, name, options: { type } });
        if (file && fileHash) {
          await doc.putAttachment({ id: fileHash, data: file, type });
        }
      };

      await enqueueLoadingSnackbar({
        loadingText: i18n.t('offlineDocuments.notification.loadingAdded'),
        finishedText: i18n.t('offlineDocuments.notification.added'),
        func: saveDocument,
        preventDuplicate: true,
        finishedVariant: 'success',
      });
    },
    [doc, enqueueLoadingSnackbar, fetchDocument]
  );

  const deleteOfflineDocument = useCallback(
    async (hash) => {
      const deleteDocument = async () => {
        const attachment = doc.getAttachment(hash);
        if (attachment) {
          await attachment.remove();
        }
      };

      await enqueueLoadingSnackbar({
        loadingText: i18n.t('offlineDocuments.notification.loadingDeleted'),
        finishedText: i18n.t('offlineDocuments.notification.deleted'),
        func: deleteDocument,
        preventDuplicate: true,
        finishedVariant: 'success',
      });
    },
    [doc, enqueueLoadingSnackbar]
  );

  const getDocument = (hash) => doc.getAttachment(hash);

  return {
    addOfflineDocument,
    deleteOfflineDocument,
    fetchDocument,
    getDocument,
  };
};

export default useOfflineDocuments;
