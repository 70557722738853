import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { SECONDARY_TRIGGER_PROPS } from '@consts';
import AboutDialog from '../AppBar/AboutDialog';
import BadgeStages from '../AppBar/BadgeStages';
import LoginForm from './LoginForm';
import TwoFactor from './TwoFactor';
import RefreshPassword from './RefreshPassword';

const PASSWORD_EXPIRED = 'PASSWORD_EXPIRED';
const SLIDE_ANIMATION_MS = 200;
const LOGIN_BOX_MAX_WIDTH = 500;

const Login = (props) => {
  const { loginStatus, onLogin, ssoLink, updateGlobalState, twoFactorPending } = props;
  const [isOpenAbout, setIsOpenAbout] = useState(false);

  const isPasswordExpired = loginStatus === PASSWORD_EXPIRED;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: 2,
      }}
    >
      <AboutDialog isOpen={isOpenAbout} onClose={() => setIsOpenAbout(false)} />
      <Slide in timeout={SLIDE_ANIMATION_MS}>
        <Paper
          sx={{
            padding: 2,
            maxWidth: LOGIN_BOX_MAX_WIDTH,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: (theme) => theme.spacing(2),
          }}
          variant="outlined"
        >
          <BadgeStages />
          <Box>
            {twoFactorPending ? (
              <TwoFactor onLogin={onLogin} updateGlobalState={updateGlobalState} />
            ) : isPasswordExpired ? (
              <RefreshPassword updateGlobalState={updateGlobalState} />
            ) : (
              <LoginForm onLogin={onLogin} />
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: (theme) => theme.spacing(),
                gap: (theme) => theme.spacing(2),
                cursor: 'pointer',
              }}
            >
              <Trigger {...SECONDARY_TRIGGER_PROPS} type="text" onClick={() => setIsOpenAbout(true)}>
                {i18n.t('label.contact')}
              </Trigger>
              {ssoLink}
            </Box>
          </Box>
        </Paper>
      </Slide>
    </Box>
  );
};

Login.propTypes = {
  loginStatus: PropTypes.string,
  onLogin: PropTypes.func.isRequired,
  ssoLink: PropTypes.element,
  twoFactorPending: PropTypes.bool,
  updateGlobalState: PropTypes.func.isRequired,
};

export default Login;
