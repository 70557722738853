import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import NotFoundIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';

import { i18n } from '@geomagic/i18n';
import { DEFAULT_TEXT_FIELD_PROPS, PRIMARY_TRIGGER_PROPS } from '@consts';

import PasswordValidationChips from './PasswordValidationChips';
import Placeholder from '../Placeholder';

const EMPTY_STRING = '';

const PasswordForm = (props) => {
  const { loadingPasswordSetting, onHandleCancel, passwordCriteria, setValidPassword } = props;

  const [isValidPassword, setIsValidPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmedPassword, setConfirmedPassword] = useState(null);

  const hasOldPasswordError = !oldPassword?.length && oldPassword !== null;
  const hasNewPasswordError = newPassword === oldPassword && oldPassword !== null && newPassword !== null;
  const hasConfirmedPasswordError =
    newPassword !== confirmedPassword && newPassword !== null && confirmedPassword !== null;

  const canSave =
    !isValidPassword &&
    !hasOldPasswordError &&
    !hasNewPasswordError &&
    !hasConfirmedPasswordError &&
    oldPassword &&
    newPassword &&
    confirmedPassword;

  /* EFFECTS */

  useEffect(() => {
    if (canSave) {
      setValidPassword({ oldPassword, newPassword });
    } else {
      setValidPassword(null);
    }
  }, [canSave, setValidPassword, newPassword, oldPassword]);

  /* EVENT HANDLER */

  const handleSubmitState = (state) => setIsValidPassword(state);

  const handleConfirmedPassword = (event) => setConfirmedPassword(event.target.value || EMPTY_STRING);

  const handleSetOldPassword = (event) => setOldPassword(event.target.value || EMPTY_STRING);

  const handleSetNewPassword = (event) => setNewPassword(event.target.value || EMPTY_STRING);

  /* COMPONENTS */

  return (
    <>
      {loadingPasswordSetting ? (
        <Typography>{i18n.t('enterPassword.placeholder.title')}</Typography>
      ) : passwordCriteria ? (
        <>
          <FormControl fullWidth margin="none">
            <TextField
              {...DEFAULT_TEXT_FIELD_PROPS}
              autoFocus
              error={hasOldPasswordError}
              fullWidth
              helperText={hasOldPasswordError && i18n.t('error.oldPassword')}
              label={i18n.t('label.oldPassword')}
              onChange={handleSetOldPassword}
              required
              type="password"
              value={oldPassword || EMPTY_STRING}
            />
          </FormControl>
          <FormControl fullWidth margin="none">
            <TextField
              {...DEFAULT_TEXT_FIELD_PROPS}
              error={hasNewPasswordError}
              fullWidth
              helperText={
                (hasNewPasswordError && oldPassword && i18n.t('error.newPassword')) ||
                (hasNewPasswordError && !oldPassword && i18n.t('error.oldPasswordNotSet'))
              }
              label={i18n.t('label.newPassword')}
              onChange={handleSetNewPassword}
              required
              type="password"
              value={newPassword || EMPTY_STRING}
            />
          </FormControl>
          <FormControl fullWidth margin="none">
            <TextField
              {...DEFAULT_TEXT_FIELD_PROPS}
              disabled={hasNewPasswordError}
              error={hasConfirmedPasswordError}
              fullWidth
              helperText={hasConfirmedPasswordError && i18n.t('error.confirmPassword')}
              label={i18n.t('label.confirmPassword')}
              onChange={handleConfirmedPassword}
              required
              type="password"
              value={confirmedPassword || EMPTY_STRING}
            />
          </FormControl>
          <PasswordValidationChips
            onCheckPasswordCriteria={handleSubmitState}
            passwordCriteria={passwordCriteria.getPasswordSettings}
            passwordInputValue={newPassword || EMPTY_STRING}
          />
        </>
      ) : (
        <Placeholder
          action={
            <Button onClick={onHandleCancel} {...PRIMARY_TRIGGER_PROPS}>
              {i18n.t('enterPassword.goBack')}
            </Button>
          }
          icon={<NotFoundIcon />}
          subtitle={i18n.t('enterPassword.noPasswordsetting')}
        />
      )}
    </>
  );
};

PasswordForm.propTypes = {
  loadingPasswordSetting: PropTypes.bool,
  onHandleCancel: PropTypes.func,
  passwordCriteria: PropTypes.object,
  setValidPassword: PropTypes.func,
};

export default PasswordForm;
