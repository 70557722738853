import React from 'react';
import PropTypes from 'prop-types';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RoomIcon from '@mui/icons-material/Room';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles, Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { DEFAULT_TRIGGER_ICON_PROPS, MOBILE_TRIGGER_SIZE } from '@consts';
import useToggleActionDrawer from '@utils/useToggleActionDrawer';
import getFeatures from './getFeatures';
import ClaimAssignment from './ClaimAssignment';
import getTasksAmount from '../Tasks/getTasksAmount';

const useStyles = makeStyles()(({ breakpoints }) => ({
  root: {
    display: 'flex',
  },
  trigger: {
    [breakpoints.down('md')]: {
      height: MOBILE_TRIGGER_SIZE,
      width: MOBILE_TRIGGER_SIZE,
    },
  },
}));

const AssignmentListActions = (props) => {
  const {
    data,
    isTeamAssignment,
    onClaimAssignment,
    onCloseSwipeableArea,
    onOpenTasks,
    onShowOnMap,
    onUpdateAssignment,
  } = props;

  const entity = data.entity;
  const { processInstances } = entity;

  const features = getFeatures([data]);
  const hasFeatures = features?.length > 0;

  const toggleActionDrawer = useToggleActionDrawer();

  const { classes } = useStyles();

  /**
   *  EVENT HANDLER
   */

  const handleOpenDrawer = (event) => {
    event.stopPropagation();

    toggleActionDrawer({
      title: entity?.displayName,
      items: menuItems,
    });
  };

  const handleOpenTasks = (event) => {
    event.stopPropagation();
    onOpenTasks && onOpenTasks(data);
  };

  const handleShowOnMap = (event) => {
    event.stopPropagation();
    onCloseSwipeableArea();
    onShowOnMap(features, entity.id);
  };

  /**
   *  MENU ITEMS
   */

  const counterTasks = getTasksAmount(processInstances) || 0;

  const getMenuItems = () => {
    const items = [];

    if (isTeamAssignment && onShowOnMap) {
      items.push({
        disabled: !hasFeatures,
        id: 'showOnMap',
        label: i18n.t('label.showOnMap'),
        onClick: handleShowOnMap,
      });
    }

    items.push({
      id: 'tasks',
      label: i18n.t('process.label.menuItem', {
        variables: {
          amount: String(counterTasks),
        },
      }),
      onClick: handleOpenTasks,
    });

    return items;
  };

  const menuItems = getMenuItems();

  return (
    <div className={classes.root}>
      {isTeamAssignment ? (
        <ClaimAssignment
          data={data}
          onClaimAssignment={onClaimAssignment}
          onUpdateAssignment={onUpdateAssignment}
          processInstances={processInstances}
        />
      ) : (
        hasFeatures && (
          <Tooltip title={i18n.t('tooltip.showOnMap')}>
            <Trigger
              {...DEFAULT_TRIGGER_ICON_PROPS}
              className={classes.trigger}
              icon={<RoomIcon />}
              onClick={handleShowOnMap}
            />
          </Tooltip>
        )
      )}

      {menuItems.length > 0 && (
        <Tooltip title={i18n.t('tooltip.options')}>
          <Trigger
            {...DEFAULT_TRIGGER_ICON_PROPS}
            className={classes.trigger}
            icon={<MoreVertIcon />}
            onClick={handleOpenDrawer}
          />
        </Tooltip>
      )}
    </div>
  );
};

AssignmentListActions.propTypes = {
  data: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  isTeamAssignment: PropTypes.bool.isRequired,
  mapProps: PropTypes.object.isRequired,
  onClaimAssignment: PropTypes.func,
  onCloseSwipeableArea: PropTypes.func.isRequired,
  onOpenTasks: PropTypes.func.isRequired,
  onShowOnMap: PropTypes.func.isRequired,
  onUpdateAssignment: PropTypes.func.isRequired,
};

export default AssignmentListActions;
