import React from 'react';
import PropTypes from 'prop-types';

import { default as CardAction } from '@mui/material/CardActions';

const CardActions = (props) => {
  const { children } = props;

  return (
    <CardAction
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        margin: 0,
        paddingRight: 0,
      }}
    >
      {children}
    </CardAction>
  );
};

CardActions.propTypes = {
  children: PropTypes.node,
};

export default CardActions;
