import getFeatureCollection from '@components/FormComponent/utils/getFeatureCollection';

const getFeatures = (assignments) => {
  let features = [];

  assignments.forEach((assignment) => {
    const entity = assignment.entity;
    const { id, forms } = entity;
    const featureCollection = getFeatureCollection({ parentId: id, forms });

    if (featureCollection) {
      const newFeatures = featureCollection?.features.map((feature) => ({
        ...feature,
        properties: { ...feature?.properties, entity },
      }));

      features = [...features, ...newFeatures];
    }
  });

  return features;
};

export default getFeatures;
