import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';

import Badge from '@mui/material/Badge';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import InfoIcon from '@mui/icons-material/Info';

import { makeStyles, TriggerMenu } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import { DATETIME_DISPLAY_FORMAT, MOBILE_TRIGGER_SIZE } from '@consts';
import MutationLogout from '@graphql/mutations/MutationLogout';
import useServiceWorker from '@serviceWorker/useServiceWorker';
import showModal from '@utils/showModal';
import getMenuItems from './getMenuItems';

const PASSOWRD_CHANGE_ID = 'passwordChange';

const useStyles = makeStyles()(({ breakpoints }) => ({
  root: {
    [breakpoints.down('md')]: {
      height: MOBILE_TRIGGER_SIZE,
      width: MOBILE_TRIGGER_SIZE,
    },
  },
}));

const AppBarMenu = (props) => {
  const { isMobile, isOnline, lastFetch, onLogout, setIsOpenAbout, user } = props;
  const menuItems = getMenuItems();
  const { classes } = useStyles();
  const { isUpdateAvailable, updateAssets } = useServiceWorker();

  const userId = user?.id;
  const lastFetchUser = typeof lastFetch === 'number' ? lastFetch : lastFetch && userId && lastFetch[userId];

  const isVisibleOnline = isOnline && user;

  /**
   *  GENERAL PROPS
   */

  const triggerProps = {
    className: classes.trigger,
    color: 'inherit',
  };

  const iconSX = {
    minWidth: (theme) => theme.spacing(4),
  };

  /**
   *  MUTATIONS
   */

  const [handleLogout] = useMutation(MutationLogout);

  /**
   *  EVENT HANDLER
   */

  const handleClickListItem =
    ({ label, Component }) =>
    () => {
      showModal({
        title: label,
        content: <Component {...props} />,
        isFullScreen: isMobile,
        onCancelText: i18n.t('button.close'),
      });
    };

  const handleClickLogout = (event) => {
    handleLogout().then(() => {
      onLogout();
    });
  };

  /**
   * COMPONENTS
   */

  const IconComponent = (
    <Badge color="secondary" variant="dot" invisible={!isUpdateAvailable}>
      <SettingsIcon />
    </Badge>
  );

  return (
    <TriggerMenu
      {...triggerProps}
      className={classes.root}
      icon={IconComponent}
      variant={!isMobile ? 'text' : 'icon'}
      triggerText={!isMobile ? user?.loginName : null}
    >
      {isUpdateAvailable && (
        <ListItem button onClick={updateAssets}>
          <ListItemText primary={i18n.t('label.refreshVersion')} />
          <ListItemSecondaryAction>
            <NewReleasesIcon />
          </ListItemSecondaryAction>
        </ListItem>
      )}
      <ListItem divider>
        <ListItemText
          primary={i18n.t('label.lastFetch')}
          secondary={lastFetchUser ? dayjs(lastFetchUser).format(DATETIME_DISPLAY_FORMAT) : i18n.t('label.noLastFetch')}
        />
      </ListItem>
      <ListItem button onClick={() => setIsOpenAbout((newOpen) => !newOpen)} divider>
        <ListItemIcon
          sx={{
            ...iconSX,
          }}
        >
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary={i18n.t('dialog.about.title')} />
      </ListItem>
      {menuItems.map((item) => {
        const isPasswordChangePage = PASSOWRD_CHANGE_ID === item.id && isVisibleOnline;
        const isVisibleMenuItem = isPasswordChangePage || PASSOWRD_CHANGE_ID !== item.id;

        return (
          <>
            {isVisibleMenuItem && (
              <ListItem key={item.id} button onClick={handleClickListItem(item)} divider>
                <ListItemIcon
                  sx={{
                    ...iconSX,
                  }}
                >
                  {item.Icon}
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            )}
          </>
        );
      })}
      {isVisibleOnline && (
        <ListItem button onClick={handleClickLogout}>
          <ListItemIcon
            sx={{
              ...iconSX,
            }}
          >
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={i18n.t('button.logout', { variables: { username: user?.loginName } })} />
        </ListItem>
      )}
    </TriggerMenu>
  );
};

AppBarMenu.propTypes = {
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  isOpenAbout: PropTypes.bool.isRequired,
  lastFetch: PropTypes.object,
  onLogout: PropTypes.func.isRequired,
  setIsOpenAbout: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default AppBarMenu;
