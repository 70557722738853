import gql from 'graphql-tag';

const MutationVerifySecondFactor = gql`
  mutation MutationVerifySecondFactor($code: Int!) {
    verifySecondFactor(code: $code) {
      status
      user {
        loginName
        fullName
        id
      }
    }
  }
`;

export default MutationVerifySecondFactor;
