import gql from 'graphql-tag';

export const MutationAbortTwoFactor = gql`
  mutation MutationAbortTwoFactor {
    abortLogin {
      status
      user {
        loginName
        fullName
        id
      }
    }
  }
`;

export default MutationAbortTwoFactor;
