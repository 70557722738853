import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { i18n } from '@geomagic/i18n';

import capitalizeFirstLetter from './utils/capitalizeFirstLetter';
import PasswordCriterionChip from './PasswordCriterionChip';

const DIGIT = /\d/g;
const LOWERCASE_CHARACTER = /[a-z]/g;
const SPECIAL_CHARACTER = /\W/g;
const UPPERCASE_CHARACTER = /[A-Z]/g;

const PasswordValidationChips = (props) => {
  const { passwordInputValue, passwordCriteria, onCheckPasswordCriteria } = props;

  const { enabled, ...rest } = passwordCriteria;
  const filteredCriteria = rest;

  // use reducer to create a single state for all criteria
  const initialValidationState = Object.keys(filteredCriteria).reduce((acc, key) => {
    acc[key] = true;
    return acc;
  }, {});

  const [validationState, setValidationState] = useState(initialValidationState);

  const checkPassword = (password) => {
    const [newValidationState] = [filteredCriteria].map((criterion) => {
      const minLength = password.length >= criterion.minLength;
      const numDigit = password.match(DIGIT)?.length >= criterion.numDigit;
      const numSpecial = password.match(SPECIAL_CHARACTER)?.length >= criterion.numSpecial;
      const numUpper = password.match(UPPERCASE_CHARACTER)?.length >= criterion.numUpper;
      const numLower = password.match(LOWERCASE_CHARACTER)?.length >= criterion.numLower;

      return {
        minLength,
        numDigit,
        numSpecial,
        numUpper,
        numLower,
      };
    });

    setValidationState(newValidationState);
  };

  /* EFFECTS */

  useEffect(() => {
    checkPassword(passwordInputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordInputValue]);

  useEffect(() => {
    const criterionToCheck = Object.keys(filteredCriteria).filter((key) => filteredCriteria[key] > 0);
    const allValid = criterionToCheck.every((key) => validationState[key]);
    onCheckPasswordCriteria(!allValid); // disable the submit button if not all criteria are met
  }, [validationState, filteredCriteria, onCheckPasswordCriteria]);

  /* COMPONENTS */

  return enabled ? (
    <Box
      sx={{
        marginTop: ({ spacing }) => spacing(2),
      }}
    >
      <Divider />
      <Typography
        variant="body2"
        sx={{
          marginTop: ({ spacing }) => spacing(2),
        }}
        color="textSecondary"
      >
        {i18n.t('enterPassword.titleCriteria')}
      </Typography>
      <span
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {Object.keys(filteredCriteria).map(
          (key) =>
            filteredCriteria[key] > 0 && (
              <PasswordCriterionChip
                key={key}
                localizationKey={`enterPassword.valid${capitalizeFirstLetter(key)}`}
                isValid={validationState[key]}
                settingsValue={filteredCriteria[key]}
              />
            )
        )}
      </span>
    </Box>
  ) : null;
};

PasswordValidationChips.propTypes = {
  onCheckPasswordCriteria: PropTypes.func,
  passwordCriteria: PropTypes.object,
  passwordInputValue: PropTypes.string,
};

export default PasswordValidationChips;
