import gql from 'graphql-tag';

const QueryPasswordSettings = gql`
  query PasswordSettings($resetToken: String) {
    getPasswordSettings(resetToken: $resetToken) {
      enabled
      minLength
      numDigit
      numSpecial
      numUpper
      numLower
    }
  }
`;

export default QueryPasswordSettings;
