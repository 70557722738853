import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import pointer from 'json-pointer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from 'notistack';
import { useRxDB, useRxData } from 'rxdb-hooks';
import { makeStyles } from '@geomagic/core';
import { getEntityType, getReference } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import StackedDialog from '@geomagic/nam-react-core/components/StackedDialog';
import { ContentRoot } from '@geomagic/layout';
import { ENTITY_SELECTOR_KEY } from '@database/consts';
import getAfter from '@synchronization/dispatch/getAfter';
import checkSyncErrors from '@database/checkSyncErrors';
import getDefaultSync from '@database/getDefaultSync';
import getUpdatedDoc from '@database/getUpdatedDoc';
import getPatch from '@database/getPatch';
import resetDoc from '@database/resetDoc';
import { CLASSNAME_DISPATCH } from '@graphql/consts';
import QueryDispatch from '@graphql/queries/QueryDispatch';
import useShowPrompt from '@utils/useShowPrompt';
import AddDraftTrigger from './AddDraftTrigger';
import DispatchWizard from './DispatchWizard';
import DispatchList from './DispatchList';

const useStyles = makeStyles()(() => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
  };
});

const getFilteredElementPatch = ({ entity, path, filterId }) => {
  const items = pointer.get(entity, path);
  const filteredItems = items.filter((item) => item.id !== filterId);

  return {
    op: 'replace',
    path,
    value: filteredItems,
  };
};

const DISPATCH_TYPE = 'Dispatch';

const Dispatch = (props) => {
  const {
    areMapActionsDisabled = false,
    client,
    collectionName = 'dispatches',
    dispatchCreationConfig,
    entityClasses,
    getPreviousMap,
    getDocRelations,
    isDetail = false,
    isDocumentsHidden = false,
    isGrouped,
    isLoading: isLoadingView,
    isMobile,
    isOnline,
    isReadOnly,
    listPlaceholderComponent,
    mapProps,
    onAddedDraft,
    onCloseSwipeableArea,
    queryProps,
    user,
  } = props;

  const userId = user.id;
  const entityClassDispatch = entityClasses?.find(
    ({ className: entityClassName }) => entityClassName === CLASSNAME_DISPATCH
  );

  const database = useRxDB();
  const queryConstructor = useCallback(
    (collection) => {
      return collection.find({ selector: { ...queryProps, userId: user.id }, sort: [{ modifiedOn: 'desc' }] });
    },
    [queryProps, user]
  );
  const { result: dispatches, isFetching } = useRxData(collectionName, queryConstructor);
  const showPrompt = useShowPrompt();
  const { enqueueSnackbar } = useSnackbar();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [dialogType, setDialogType] = useState();
  const [dialogTitle, setDialogTitle] = useState('');
  const { classes } = useStyles();
  const isSMDevice = useMediaQuery((mediaQueryTheme) => mediaQueryTheme.breakpoints.down('sm'));

  const isLoading = isLoadingView || isFetching;

  /**
   *  EVENT HANDLER
   */

  const handleClickDispatch = (doc) => {
    const { draft, entity } = doc;
    const patchedEntity = doc.getPatchedEntity();
    const entityType = getEntityType(entityClasses, patchedEntity?.className, patchedEntity?.entityType?.id);
    const title = !!draft ? entityType?.name : patchedEntity?.displayName;
    const previousMap = getPreviousMap && getPreviousMap();
    setDialogProps({ entityId: entity?.id, previousMap });
    setDialogTitle(title);
    setDialogOpen(true);
    setDialogType(DISPATCH_TYPE);
  };

  const handlePromptRemoveDraft = (name, doc) => {
    showPrompt({
      title: i18n.t('dispatch.dialog.removeDraft.title'),
      content: i18n.t('dispatch.dialog.removeDraft.content', { variables: { name } }),
      onOk: () => handleRemoveDraft(doc),
    });
  };

  const handleRemoveDraft = async (doc) => {
    const { uuid, relations } = doc;

    await doc.remove();

    if (relations) {
      const assignments = relations.filter(({ type }) => type === 'Assignment');

      for (let i = 0; i < assignments.length; i++) {
        const { id, path } = assignments[i];

        const newPatch = [];

        const collection = database.assignments;
        const selector = { [ENTITY_SELECTOR_KEY]: id };
        const assignmentDoc = await collection.findOne({ selector }).exec();
        const jsonPatch = assignmentDoc?.jsonPatch;
        const patchedEntity = assignmentDoc.getPatchedEntity();

        const patch = getFilteredElementPatch({ entity: patchedEntity, path, filterId: uuid });
        newPatch.push(patch);

        await assignmentDoc.atomicUpdate((oldData) => {
          oldData.jsonPatch = getPatch(jsonPatch, newPatch);
          return oldData;
        });
      }
    }

    enqueueSnackbar(i18n.t('dispatch.notification.removedDraft'), {
      key: 'removedDraft',
      preventDuplicate: true,
      variant: 'success',
    });
  };

  const handleSyncDispatch = async (doc) => {
    const syncErrors = [];
    const syncProps = {
      client,
      database,
      doc,
      entityClasses,
      mapProps,
      syncErrors,
      refetchQuery: QueryDispatch,
    };
    await getDefaultSync(syncProps);
    checkSyncErrors(syncErrors);
  };

  const handleUpdateDispatch = async (doc) => {
    const entityReference = getReference(doc?.entity);
    const updatedEntity = await getUpdatedDoc(client, QueryDispatch, entityReference, mapProps);

    if (updatedEntity) {
      await resetDoc(doc, updatedEntity);
      await getAfter({ updated: [doc] });
    }
  };

  const handleCloseDialog = () => setDialogOpen(false);

  /**
   *  COMPONENTS
   */

  const DialogContent =
    dialogType === DISPATCH_TYPE ? (
      <DispatchWizard
        entityClass={entityClassDispatch}
        entityClasses={entityClasses}
        handleClose={handleCloseDialog}
        isDocumentsHidden={isDocumentsHidden}
        isMobile={isMobile}
        isOnline={isOnline}
        mapProps={mapProps}
        onSyncEntity={handleSyncDispatch}
        onUpdateEntity={handleUpdateDispatch}
        setDialogTitle={setDialogTitle}
        user={user}
        {...dialogProps}
      />
    ) : null;

  return (
    <ContentRoot className={classes.root} scrollable={false} withPadding={false}>
      <DispatchList
        areMapActionsDisabled={areMapActionsDisabled}
        client={client}
        dispatchCreationConfig={dispatchCreationConfig}
        dispatches={dispatches}
        entityClasses={entityClasses}
        isCreateButton={!isReadOnly}
        isDetail={isDetail}
        isGrouped={isGrouped}
        isLoading={isLoading}
        isMobile={isMobile}
        isOnline={isOnline}
        listPlaceholderComponent={listPlaceholderComponent}
        mapProps={mapProps}
        onClick={handleClickDispatch}
        onCloseSwipeableArea={onCloseSwipeableArea}
        onRemoveDraft={handlePromptRemoveDraft}
        user={user}
      />
      <StackedDialog
        content={DialogContent}
        isFullscreen={isMobile || dialogType === DISPATCH_TYPE}
        handleClose={handleCloseDialog}
        open={isDialogOpen}
        title={dialogTitle}
      />
      {!isReadOnly && (
        <AddDraftTrigger
          entityClass={entityClassDispatch}
          entityClasses={entityClasses}
          getDocRelations={getDocRelations}
          getPreviousMap={getPreviousMap}
          isMobile={isMobile}
          mapProps={mapProps}
          onAddedDraft={onAddedDraft}
          triggerText={!isSMDevice ? i18n.t('dispatch.button.newDraft') : ''}
          user={user}
          userId={userId}
        />
      )}
    </ContentRoot>
  );
};

Dispatch.propTypes = {
  areMapActionsDisabled: PropTypes.bool,
  client: PropTypes.object.isRequired,
  collectionName: PropTypes.string,
  dispatchCreationConfig: PropTypes.object,
  entityClasses: PropTypes.array.isRequired,
  getDocRelations: PropTypes.func,
  getPreviousMap: PropTypes.func,
  isDetail: PropTypes.bool,
  isDocumentsHidden: PropTypes.bool.isRequired,
  isGrouped: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  listPlaceholderComponent: PropTypes.node,
  mapProps: PropTypes.object.isRequired,
  onAddedDraft: PropTypes.func,
  onCloseSwipeableArea: PropTypes.func,
  queryProps: PropTypes.object,
  user: PropTypes.object,
};

export default Dispatch;
